<template>
  <div class="container-fluid multiple-tabs  news-container">
    <Form @submit="saveNews" v-slot="{ errors }">
      <div class="d-flex justify-content-between align-items-center flex-wrap">
        <Breadcrumb :parent_page="{title: 'الأخبار' , path: '/all-news'}" current_page="إضافة خبر"/>
        <div class="controls-btns d-flex gap-2 mb-3">
          <!--        <button class="btn btn-save">نشر وعودة للأرشيف</button>-->
          <button class="btn btn-publish" type="submit" @click="saveNews">نشر</button>
          <button class="btn btn-save-draft">حفظ مسودة</button>
          <!--        <button class="btn btn-preview">معاينة</button>-->
        </div>
      </div>
      <el-card>
        <el-tabs v-model="activeTab">
          <el-tab-pane label="الخبر" name="news">
            <NewsDetails :newsDetails="news" :contentError="contentError" :errors="errors" @update="handleOnChange"/>
          </el-tab-pane>
          <el-tab-pane label="الصور" name="media">
            <MediaTab @update="handleOnChange"/>
          </el-tab-pane>
          <el-tab-pane label="محركات البحث" name="seo">
            <SEO :seoData="news" @update="handleOnChange"/>
          </el-tab-pane>
          <el-tab-pane label="منصات التواصل الاجتماعي" name="social-media">
            <SocialMediaTab :socialData="news" @update="handleOnChange"/>
          </el-tab-pane>
        </el-tabs>
      </el-card>
    </Form>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import NewsDetails from "./components/newsDetails";
import SEO from "@/views/news/components/SEO";
import SocialMediaTab from "@/views/news/components/SocialMediaTab";
import {Form} from "vee-validate";
import MediaTab from "@/views/news/components/MediaTab";

import {createNews, saveDraft} from "@/services/news";
import {useToast} from "vue-toastification";

export default {
  name: "Add_news",
  data()
  {
    return {
      toast: useToast(),
      activeTab   : "news",
      news        : {
        id                    : "",
        highlight_title       : "",
        title                 : "",
        source                : "",
        content               : "",
        summary               : "",
        extract               : "",
        video                 : "",
        is_news_ticker        : false,
        is_main_news          : false,
        is_special_news       : false,
        is_hidden_news        : false,
        is_particular_news    : false,
        is_shown_in_template  : true,
        share_facebook        : false,
        is_notify             : false,
        share_facebook_offical: false,
        is_instant            : false,
        is_amp                : false,
        twitter               : false,
        use_watermark         : false,
        // slug: '',
        seo_title         : "",
        seo_description   : "",
        social_title      : "",
        social_description: "",
        social_image      : "",
        author_id         : "",
        author            : "",
        type_id           : "",
        newsfile_id       : "",
        image             : "",
        image_description : "",
        date_to_publish   : new Date(),
        time              : new Date().toLocaleTimeString("en-US", {
          hour  : "numeric",
          hour12: false,
          minute: "numeric",
        }),
        category_id       : "",
        tags              : [],
        image_edited      : false,
        simage_edited     : false,
        twitter_edited    : 1,
        photos            : [],
      },
      hasError    : false,
      contentError: false,
    };
  },
  components: {MediaTab, SocialMediaTab, SEO, Breadcrumb, NewsDetails, Form},
  mounted()
  {
    this.handleDraftObserver();
  },
  unmounted()
  {
    clearInterval(this.interval)
  },
  methods: {
    handleOnChange(key, val)
    {
      this.news = {
        ...this.news,
        [key]: val
      }
    },

    saveNewsDraft()
    {
      if (this.news.title.length > 10 && this.news.content.length > 10 && this.news.category_id)
      {
        saveDraft(this.news).then(response => {
          this.toast.success('تم الحفظ بنجاح')
          console.log(response)
          this.news.id = response.data.id
        })
      }
    },

    handleDraftObserver()
    {
      this.interval = setInterval(this.saveNewsDraft, 10000)
    },

    saveNews()
    {
      if (this.news.content.length === 0)
      {
        this.contentError = true
      } else
      {
        createNews(this.news).then(() => {
          this.toast.success('تم الحفظ بنجاح')
          this.$router.push('/all-news')
        })
            .catch(e => {
              console.log(e)
            })
      }

    }

  }
};
</script>

<style lang="scss">
.news-container {
  margin-top: 1rem;

  .el-card__body {
    padding: 10px 15px;
  }

  .el-tabs__nav {
    float: right;
  }

}

</style>
